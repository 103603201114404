<template>
  <div pagination class="para">
    <a class="prev" @click="prev" :class="{disabled: pageNow === 1}"></a>
    <div class="page-go">
      <a v-for="page in pageList" :key="page" :class="{on: page === pageNow }" @click="$emit('go', page)">{{ page }}</a>
    </div>
    <a class="next" @click="next" :class="{disabled: totalPages === pageNow}"></a>
  </div>
</template>

<script>
export default {
  props: {
    paging: { type: Object, default: null },
  },
  data() {
    return {
      totalPages: 0,
      pageNow: 3,
      pageStart: 0,
      pageEnd: 0,
      pageList: 5,
    };
  },
  watch: {
    paging: 'update',
  },
  methods: {
    prev() {
      if (this.pageNow === 1) return;
      this.$emit('go', this.pageNow - 1);
    },
    next() {
      if (this.pageNow === this.totalPages) return;
      this.$emit('go', this.pageNow + 1);
    },
    update() {
      const result = [];
      if (!this.paging) {
        result.push(1);
        return result;
      }
      if (this.paging) {
        const len = 5;
        const c = this.paging.pageNo - 1;
        const t = this.paging.pageCount;
        const s = (Math.floor(c / len) * len) + 1;
        const e = s + (t - s > len - 1 ? len - 1 : t - s);
        this.pageNow = this.paging.pageNo;
        this.totalPages = t;
        this.pageStart = s;
        this.pageEnd = e;
        for (let i = s; i <= e; i += 1) result.push(i);
      } else {
        this.pageNow = 0;
        this.totalPages = 0;
        this.pageStart = 0;
        this.pageEnd = 0;
        result.push(1);
      }
      this.pageList = result;
    }
  },
  mounted() {
    this.update()
  }
};
</script>
<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;

[pagination] { .mt(50); .tc;
  > a { .ib; .vam;
    &.prev { .wh(60,61); .contain('/images/mo/paging-left.png'); }
    &.next { .wh(60,61); .contain('/images/mo/paging-right.png'); }
  }
  .page-go { .ib; .vam; .p(0,20); .-box;
    a { .fs(21); .lh(60); .m(0,10); .w(40); .ib; .c(#1d1d1d); .rel;
      &.on { .c(#fff);
        &:after { .cnt; .wh(40,40); .br(50%); .bgc(#0079ae); .abs; .lt(50%,50%); transform: translate(-50%,-50%); z-index: -1; }
      }
    }
  }
}
@media screen and(min-width: 1024px) {
  [pagination] {
    > a {
      &.prev { .wh(47,47); .contain('/images/pc/paging-left.png'); }
      &.next { .wh(47,47); .contain('/images/pc/paging-right.png'); }
    }
    .page-go {
      a { .fs(16); .lh(47); .m(0,7);
        &.on {
          &:after { .cnt; .wh(30,30); }
        }
      }
    }
  }
}
</style>