<template>
  <div story>
    <visual tit="STORY" txt="현대제철이 만든 프리미엄 강재<br> <b>H CORE</b>의 다양한 이야기를 전해 드립니다" bg="story-kv"/>
    <Tab :list="selectList" :active="active" @select="select"/>
    <article>
      <Paragraph class="content adv" v-if="active === 'adv'" key="adv">
        <div class="cnt-wrap adv-wrap">
          <h3 class="para">인쇄광고</h3>
          <ul class="para">
            <li v-for="adv in printAd" :key="adv.sq">
              <a @click="storyModal(adv);"><img :src="thumb(adv)" alt=""></a>
            </li>
          </ul>
          <Pagination :paging="printPaging" @go="printGo" />
<!--          <div class="paging">-->
<!--            <a class="prev"></a>-->
<!--            <a class="next"></a>-->
<!--          </div>-->
        </div>
        <div class="cnt-wrap promo-wrap">
          <h3 class="para">TVC | 홍보영상</h3>
          <div class="main-vid para" v-if="focused">
            <div class="vid">
              <iframe :src="`https://www.youtube.com/embed/${getId(focused)}?playsinline=1`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="tit-wrap">
              <p class="tit para">{{ focused.title }}</p>
              <p class="txt para" v-html="focused.description"></p>
            </div>
          </div>
          <div class="vids para">
            <ul :class="'vid-length'+vidLength">
              <li v-for="vid in videoAd" :key="vid.sq" @click="focusVideo(vid)">
                <img :src="'/api/files/'+vid.files[0].location" alt="" style="width: 100%;">
                <p>{{ vid.title }}</p>
              </li>
            </ul>
          </div>
          <Pagination :paging="videoPaging" @go="videoGo" />
        </div>
      </Paragraph>
      <Paragraph class="content news" v-if="active === 'news'" key="news">
        <h3 class="para">H CORE News</h3>
        <div class="filter para">
          <div class="select" :class="{on:filterOpen}">
            <a class="selected" @click="selectOpen">{{ $route.query.category || '전체보기' }}</a>
            <ul v-if="filterOpen">
              <li><a @click="filterNews('')">전체보기</a></li>
              <li v-for="category in newsCategory" :key="category"><a @click="filterNews(category)">{{ category }}</a></li>
            </ul>
          </div>
        </div>
        <News :news="news" />
        <Pagination :paging="newsPaging" @go="newsGo"/>
      </Paragraph>
    </article>
  </div>
</template>

<script>
import Visual from "@/views/components/Visual";
import Tab from "@/views/components/Tab";
import Pagination from "@/views/components/Pagination";
import StoryModal from "@/views/components/modal/StoryModal";
import Paragraph from "@/views/components/Paragraph";
import News from "@/views/components/News";
import axios from 'axios'

export default {
  name: "Story",
  components: {News, Paragraph, Visual, Tab, Pagination},
  data() {
    return {
      printAd: [],
      printPaging: null,
      videoAd: [],
      videoPaging: null,
      news: [],
      newsPaging: null,
      scrollY: 0,
      winH: 0,
      filterOpen: false,
      focused: null,
      vidLength: 0,
      newsCategory: [],
    }
  },
  async asyncData({ host, route }) {
    if (route.params.story === 'adv') {
      const { data: print } = await axios.get(host + '/api/board/ad', { params: { perPage: 8, category: '인쇄광고', pageNo: route.query.printPage ?? 1, _: +new Date() } });
      const { data: video } = await axios.get(host + '/api/board/ad', { params: { perPage: 3, category: '영상광고', pageNo: route.query.videoPage ?? 1, _: +new Date() } });
      return { printAd: print.list, printPaging: print.paging, videoAd: video.list, videoPaging: video.paging };
    } else {
      const { data } = await axios.get(host + '/api/board/news', { params: { perPage: 8, pageNo: route.query.newsPage ?? 1, category: route.query.category, _: +new Date() } });
      return { news: data.list, newsPaging: data.paging };
    }
  },
  created() {
    this.focused = this.videoAd[0];
    this.vidLength = this.videoAd.length;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    query() {
      return JSON.stringify(this.$route.query);
    },
    active() {
      return this.$route.params.story
    },
    selectList() {
      return [
        {key: 'adv', label: 'H CORE AD'},
        {key: 'news', label: 'H CORE News'}
      ]
    }
  },
  methods: {
    thumb(adv) {
      return '/api/files/'+adv.files.find(f => f.name === 'thumb').location;
    },
    select(story) {
      if (this.active === story) return;
      this.$router.push({ params: { story } })
    },
    storyModal(adv) {
      this.$modal(StoryModal, {img: adv.files.find(f => f.name === 'large').location });
    },
    selectOpen() {
      this.filterOpen = !this.filterOpen;
    },
    filterNews(v) {
      this.filterOpen = false;
      const category = v || undefined;
      if (category === this.$route.query.category) return;
      this.$router.replace({ query: {...this.$route.query, newsPage: '1', category } });
    },
    printGo(p) {
      if (`${p}` === this.$route.query.printPage) return;
      this.$router.replace({ query: {...this.$route.query, printPage: p } });
    },
    videoGo(p) {
      if (`${p}` === this.$route.query.videoPage) return;
      this.$router.replace({ query: {...this.$route.query, videoPage: p } });
    },
    newsGo(p) {
      if (`${p}` === this.$route.query.newsPage) return;
      this.$router.replace({ query: {...this.$route.query, newsPage: p } });
    },
    focusVideo(vid) {
      this.focused = vid;
    },
    getId(vid) {
      return vid.link.match(/(?:youtu\.be\/|embed\/|\?v=)([\w_-]+)/)[1];
    }
  },
  async mounted() {
    const { data } = await axios.get('/api/category/news', { params: { _: +new Date() } });
    this.newsCategory = data;
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[story] {
  article { .bgc(#fff); .rel;
    .content { .p(170,30,50); .-box; .clear;
      .cnt-wrap { .p(80,0); .-box; }
      h3 { .fs(36); .lh(40); .bold; .mb(26); .mt(-63); }
      .adv-wrap { .pt(0); .rel;
        ul { .flex; .space-between; .flex-wrap;
          li { .wh(46.3%,66vw); overflow: hidden;
            img { .wf; transition: transform 0.5s; }
            &:hover img { transform: scale(1.03); }
          }
        }
        .paging { .abs; .lt(0,50%); .wf; transform: translateY(-50%); .mt(-100);
          a { .wh(45,86); .contain('/images/mo/slide-arrow.png'); .ib; .abs; .t(0);
            &.prev { .contain('/images/mo/slide-arrow.png'); .l(-20); }
            &.next { .contain('/images/mo/slide-arrow.png'); transform: rotate(180deg); .r(-20); }
          }
        }
      }
      .promo-wrap {
        h3 { .-t(#333); .pt(70); .-box; }
        .main-vid { .wf;
          .vid { .wh(100%,51.6vw); .mh-c;
            iframe { .f; .rel; }
          }
          .tit-wrap { .mt(20);
            p { .ls(-0.025em); }
            .tit { .fs(30); .lh(36); .bold; }
            .txt { .fs(20); .lh(30); .mt(12);  white-space: pre-line; }
          }
        }
        .vids { overflow: auto; .mt(70);
          ul {
            &.vid-length2 { .w(924); }
            &.vid-length3 { .w(1401); }
            li { .ib; .vam; .wh(447,300); .mr(30); .pointer;
              img { .wh(447,252); .vat; }
              &:last-child { .mr(0); }
              p { .fs(20); .lh(36); .ls(-0.025em); .mt(12); }
            }
          }
        }
        ::-webkit-scrollbar { .hide; }
      }
      .filter { .tl; .wh(200,50); .lh(50); .pl(0); .ib; .vam; .mr(10); .-box; z-index: 1; .abs; .rt(20,100);
        .select {
          &:after { .cnt; .wh(26,13); .contain('/images/mo/ico-select-arrow.png'); .abs; .rt(16,18); pointer-events: none; }
          .selected { .fs(18); .pointer; z-index: 1; .ib; .wh(200,50); .-a(@c-black); .pl(20); .-box; .vat; }
          ul { .wh(200,0); .abs; .lt(0,46); .-box; .-t; .bgc(#fff);  }
          &.on {
            &:after { transform: rotate(180deg); }
            ul { height:auto; .-a(@c-black); .p(15,0); .-box; .bgc(#fff); z-index: 1;
              li { .fs(18); .-a; .f; .lh(40); .vat; background: transparent; .pl(20); .-box; .bgc(#fff);
                &:hover { .bgc(#eee); }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(min-width:1024px) {
  [story] {
    article {
      .content { .p(170,0,50); .max-w(1400); .mh-c;
        h3 { .fs(40); }
        .adv-wrap { .pt(0); .mb(0);
          ul { .block;
            li { .wh(320,456); .ib; .vat; .mr(40);
              &:nth-child(4n) { .mr(0); }
              img { .f; }
            }
          }
          .paging { .hide; }
        }
        .promo-wrap {
          .main-vid {
            .vid { .wh(758,425); .ib; .vat;
              iframe { .f; .rel; }
            }
            .tit-wrap { .w(550); .mt(0); .ib; .vat; .pl(85); .-box;
              .txt { .lh(36); .mt(30); }
            }
          }
          .vids { .mt(58);
            ul { .wf!important;
              li { .mr(29);
                p { .fs(18); }
              }
            }
          }
        }
        .filter { .tl; .m(-70,0,20); .wh(200,50); .lh(50); .rel; .pl(0); .fr; .ib; .-box; z-index: 1; right:auto; top:auto;
          .select {
            &:after { .cnt; .wh(26,13); .contain('/images/mo/ico-select-arrow.png'); .abs; .rt(16,18); pointer-events: none; }
            .selected { .fs(18); .pointer; z-index: 1; .ib; .f; .pl(20); .-box; .vat; }
            ul { .wh(200,0); .abs; .lt(0,51); .-box; .-t; .bgc(#fff); }
            &.on {
              &:after { transform: rotate(180deg); }
              ul { height:auto; .-a(@c-black); .p(15,0); .-box;
                li { .fs(18); .-a; .f; .lh(40); .vat; background: transparent; .pl(20); .-box;
                  &:hover { .bgc(#eee); }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
