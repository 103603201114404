<template>
  <div visual :class="{ready}">
    <div class="kv" :class="bg">
      <div class="kv-inner">
        <h2>{{ tit }}</h2>
        <p class="txt" v-html="txt"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Visual",
  props:{
    tit: { type: String, default: null },
    txt: { type: String, default: null },
    bg: { type: String, default: null }
  },
  data() {
    return {
      ready: true,
      scrollPosition: null,
      dir: null,
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() =>{ this.ready = false },500);
    window.addEventListener("scroll", this.handleScroll);
    this.scrollPosition = document.documentElement.scrollTop || 0;
  },
  watch: {
    $route(to, from) {
      if(to.path !== from.path) {
        this.$router.go(0);
      }
    }
  },
  methods: {
    handleScroll() {
      let docY = document.documentElement.scrollTop;
      this.dir = docY - this.scrollPosition >= 0 ? 1 : -1;
      this.scrollPosition = docY
      this.kvToggle();
    },
    kvToggle() {
      if(this.dir === 1) { this.ready = true; }
      if(this.scrollPosition < 10) { this.ready = false; }
    }
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[visual] { .h(400); .rel;
  .kv { .wh(100%,400); .tc; .c(#fff); overflow: hidden; .fix; .lt; transition: transform 1s; transform: scale(1); .ease-out;
    &.story-kv { background: url('/images/mo/story-kv.png')no-repeat center center; .cover; }
    &.support-kv { background: url('/images/mo/support-kv.png')no-repeat center center; .cover; }
  }
  .kv-inner { .pt(196); .-box;
    h2 { .fs(72); .lh(84); .roboc; .bold; }
    .txt { .fs(24); .lh(30); .ls(-0.05em); .thin; .mt(10);
      b { .bold; }
    }
  }
  h2, .txt { transform: translateY(0); transition: transform 0.7s, opacity 0.5s; .ease-out; }
  &.ready {
    .kv img { transform: scale(1.2); }
    h2, .txt { transform: translateY(100px); opacity: 0; }
  }
}

@media screen and(min-width:1024px) {
  [visual] { .h(400);
    .kv { .abs; .lt; .wf; .p(0,50); .-box;
      &.story-kv { background: url('/images/pc/story-kv.png')no-repeat center center; .cover; }
      &.support-kv { background: url('/images/pc/support-kv.png')no-repeat center center; .cover; }
    }
    .kv-inner { .max-w(1720); .h(400); .mh-c; .rel; .clear; .pt(270); .-box;
      h2 { .fl; .pl(165); .-box; }
      .txt { .lh(36); .tl; .fr; .w(600); }
    }
    &.ready {
      .kv img { transform: scale(1.1); }
    }
  }
}

</style>