<template>
  <div news>
    <div class="news">
      <ul>
        <li class="para" v-for="nw in news" :key="nw.sq" @click="link(nw);">
          <div class="n-img-wrap" :class="cat[nw.category]">
            <img :src="'/api/files/'+nw.files[0].location" alt="">
          </div>
          <p class="n-tit">{{ nw.title }}</p>
          <p class="n-date">{{ nw.dt }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "News",
  props: {
    news: Array
  },
  data() {
    return {
      cat: {
        인스타그램: 'ins',
        유튜브: 'yt',
        보도자료: 'nw',
      }
    };
  },
  methods: {
    link(nw) {
      window.open(nw.link)
    },
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[news] {
  ul { .flex; .space-between; .flex-wrap;
    li { .w(47.8%); .pb(50); .-box; .tl; .pointer;
      &:nth-last-child(1), &:nth-last-child(2) { .pb(0); }
      .n-img-wrap { .wf; .rel; overflow: hidden;
        img { .wf; transition: transform 0.5s; }
        &:hover img { transform: scale(1.03); }
        &:after { .cnt; .wh(43,43); .abs; .lb(20,10); .contain; }
        &.yt:after { .bg('/images/mo/ico-yt.png'); }
        &.nw:after { .bg('/images/mo/ico-nw.png'); }
        &.ins:after { .bg('/images/mo/ico-ins.png'); }
      }
      p { .lh(24); .ls(-0.025em); .mt(15); color:#0d1112; .spoqa;
        &.n-tit { .fs(24); .keep-all; }
        &.n-date { .fs(20); .o(0.3); }
      }
    }
  }
}
@media screen and(min-width:1024px) {
  [news] {
    ul { .block; .tl;
      li { .w(330); .pb(70); .-box; .ib; .vat; .mr(26);
        &:nth-child(4n) { .mr(0); }
        &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) { .pb(0); }
        p {
          &.n-tit { .fs(18);  }
          &.n-date { .fs(16); }
        }
      }
    }
  }
}
</style>
