<template>
  <Modal story-modal>
    <img :src="'/api/files/'+options.img" alt="">
    <a class="close" @click="$emit('resolve')">닫기</a>
  </Modal>
</template>

<script>
import Modal from "@/views/components/modal/Modal";
export default {
  name: "StoryModal",
  components: {Modal},
  props: ['options'],
  data() {
    return {
      winWidth: 0,
      popSrc: '',
    }
  },
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[story-modal] {
  img { .w(80%); }
}

@media screen and(min-width:1024px) {
  [story-modal] {
    .inner { .w(1000); }
  }
}
</style>